@import "../../Styles/variables";
@import "../../Styles/mixins";

.subscription-management-main {
  display: flex;
  flex-direction: column;

  .subscription-item-props {
    margin-top: 16px;
    @include mobile-only {
      display: grid;
      grid-template-columns: 1fr;
    }

    @include tablets-only {
      .tablet-padding {
        padding-left: unset;
      }
    }

    > *:first-child {
      width: 20%;
      @include mobile-only {
        width: 100%;
      }
    }
  }

  @include mobile-only {
    background: $dark-blue !important;

    .header-row {
      display: none;
    }
  }

  .header-row-title {
    display: flex;
    gap: 16px;
    align-items: center;

    @include tablets-only {
      max-width: 100%;
    }
  }

  .main-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include tablets-only {
      padding-left: 16px;
      padding-right: 16px;
    }

    .active-toggle {
      margin: auto 0;
      padding: 0 16px;

      @include mobile-only {
        padding: 0;
      }

      &.hidden {
        display: none;
      }
    }
  }

  .subscription-management-button {
    margin-top: 16px;

    @include mobile-only {
      text-transform: capitalize !important;
    }
  }
}

.subscription-plan-nav-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 24px;
}

.copy-button {
  padding: 1px 5px !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;

  i {
    margin-right: 5px;
  }
}

.subscription-management-wrapper {
  @include mobile-only {
    .left-rail {
      display: none;
    }
  }
}

.currency-select {
  margin-left: 1.5rem;

  @include mobile-only {
    margin-left: 0;
  }
}

.mobile-subscription-management-header {
  @include mobile-only {
    padding: 16px 16px 4px 16px;
    background: $body-blue !important;
  }
}

@include mobile-only {
  body {
    padding-right: unset !important;
  }
}

.input-label-properties-mobile-tablet {
  @include tablet-and-mobile {
    text-transform: none !important;
    color: white !important;
    font-weight: 400 !important;
    padding: 10px 0 0 0;
  }

  @include tablets-only {
    font-size: 1.35rem !important;
  }
}

@include tablet-and-mobile {
  .input-label-mobile-tablet-margin {
    margin-bottom: 6px;
  }
}

@include mobile-only {
  .mobile-view-container {
    display: block;
  }

  .mobile-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .mobile-row:nth-of-type(2) {
    display: grid;
    grid-template-columns: 3fr 5fr 5fr;
    gap: 10px;
  }

  .mobile-view-item {
    background: $marine-blue;
    padding: 10px;
    margin-bottom: 10px;

    &.add-option-from-wrapper {
      background: unset;
      margin-bottom: unset;

      .mobile-row:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
      }

      .mobile-row:nth-of-type(3) {
        display: grid;
        grid-template-columns: 3fr 5fr 5fr;
        gap: 10px;
      }
    }
  }

  .mobile-column {
    flex: 1;
    margin-right: 10px;
  }

  .accordion-header-subs-management {
    margin-left: -2px !important;
  }

  .mobile-chip-padding {
    padding: 10px 0;
  }

  .subscription-plan-name {
    font-size: 1.5rem;
  }

  .stripe-id-mobile {
    color: #b2bac0;
    font-size: 1.25rem;
  }

  .mobile-view-no-options {
    text-align: center;
    padding: 20px 0;
  }

  .input-label {
    font-weight: 400 !important;
  }

  .select-left-padding-mobile {
    .MuiInputBase-root {
      padding-left: unset !important;
    }
  }
}

@include tablet-and-mobile {
  .mobile-buttons {
    justify-content: space-between;

    button {
      width: 20%;
    }

    @include mobile-only {
      justify-content: center;

      button {
        width: 48%;
      }
    }
  }
}

@include tablets-only {
  .tablet-drawer-content {
    overflow-x: hidden;
    letter-spacing: 1px;
    overflow-y: auto;
  }

  .tablet-drawer-header {
    margin-left: 20px;
    min-height: 4.5714285714em;
    border-bottom: 1px solid #000000;
    height: auto;
    display: flex;
    align-items: center;
  }

  .tablet-view-container {
    background: $marine-blue;
    display: grid;
  }

  .tablet-view-item {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem 1rem 0 1rem;
    border-bottom: 1px solid $royal-blue;

    .tablet-row {
      display: grid;
      gap: 20px;
      grid-template-columns: 4fr 5fr 2fr;

      .tablet-column-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        .form-group {
          width: 70%;
        }
      }

      .toggle-column {
        text-align: end;
        margin-top: 1.5rem;
      }

      .active-toggle {
        padding: 0;
      }
    }
  }
}
