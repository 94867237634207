@import "../../Styles/variables.scss";
@import "../../Styles/mixins.scss";

.br-on-mobile {
  display: none;

  @include mobile-only {
    display: unset;
  }
}

.alert-modal-main {
  display: block;
  opacity: 1;

  .MuiStepper-root {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include mobile-small {
  div.info {
    flex-direction: column;
  }

  div.info-alert {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  p#alert-condition {
    order: 2;
    flex-basis: 80%;
  }

  p#datetime-mobile {
    order: 1;
    margin-left: auto;
    white-space: pre-line;
  }

  div.info-device {
    text-align: left;
  }

  div.info-device {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    padding-top: 0;
  }

  p#info-device-device {
    flex-basis: 80%;
    order: 2;
  }

  p#info-device-serial-num {
    margin-left: auto;
    margin-right: auto;
  }

  label.input-label {
    font-size: 12px;
  }
}

