@import '../../../Styles/variables';
@import '../../../Styles/mixins';

.graph-holder {
  background-color: $body_blue;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  #line-chart {
    position: relative;
    width: 100%;
    height: 350px;
    display: flex;
    flex: 1;
  }
}

.bb-button .bb-zoom-reset {
  color: $btn-default-color;
}

.bb-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  opacity: 0.9;
  box-shadow: 7px 7px 12px -9px #777777;
  background-color: $marine-blue;
  color: white;

  th {
    background-color: $marine-blue;
    color: $white;
  }

  td {
    background-color: $marine-blue;
    color: white;
  }
}

.bb-lines-values path {
  stroke-width: 3px;
}

.bb-axis line,
.bb-axis .tick,
.bb-axis .domain {
  stroke-width: 0.8;
  stroke: $light-teal;
}

.bb-axis line {
  stroke: $light-teal;
}

.bb-grid line {
  stroke: #00d1a42f;
  stroke-dasharray: none;
  stroke-width: 1px;
}

.points-range {
  margin-bottom: 15px;
}

.export-modal-table-wrapper {
  overflow: auto;
}

.points-of-interest-table .MuiTableBody-root .MuiTableRow-root {
  background-color: $blk-blue;
}