@import '../../Styles/variables';
@import '../../Styles/mixins';

.mobile-fullscreen {
  @include mobile-only {
    width: 100%;
    border: 0;
    margin: 0;
    height: calc(100vh - 48px);
    position: absolute;
  }
;
}

.login-logo {
  width: 156px;

  @include mobile-only {
    width: 85px;
  }
;
}

.row-to-tablet-mobile-column {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  padding: 6px 0;
  @include tablet-and-mobile {
    flex-direction: column;
  }
}

.row-to-mobile-column {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  padding: 6px 0;
  @include mobile-only {
    flex-direction: column;
  }
}

.column-to-mobile-row {
  align-items: flex-start;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  @include tablet-and-mobile {
    align-items: center;
    flex-direction: row;
    padding-top: 15px;
  }
}

.forgot-password {

  @include tablet-and-mobile {
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
    width: 100%;
    margin: 0;
  }
}

.left-button {
  order: 2;
  align-self: center;
  @include desktop-only {
    align-self: auto;
    order: 1;
  }
}

.right-button {
  align-items: center !important;
  justify-content: flex-end;
  width: 100%;
  order: 1;
  @include desktop-only {
    align-items: end !important;
    order: 2;
    width: 0;
  }
}

.flat-button {
  width: 100%;
  @include desktop-only {
    width: 0;
  }
}

.mobile-register {
  text-decoration: underline !important;
  margin: 0 !important;
}