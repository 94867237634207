@import "../../Styles/variables.scss";
@import "../../Styles/mixins.scss";

#close-button-tablet {
  display: none !important;
}

@include mobile-only {
  #close-button-tablet {
    display: block !important;
    margin-top: -3px !important;
  }
}

@include mobile-small {
  .tablet-dekstop-only, #close-button-tablet {
    display: none !important;
  }
  #close-button-mobile-small {
    margin-top: -3px !important;
  }
}

.alert-control-small-last-container {
  display: none !important;
}

@include mobile-small {
  .alert-control-small-last-container {
    display: flex !important;
  }
}

.alerts-dropdown-menu {
  &.dropdown-menu {
    width: 400px;
    @include mobile-only {
      width: unset;
      min-width: 400px !important;
      [aria-selected="false"] {
        filter: brightness(60%);
      }
      div[role="tablist"] {
        border-bottom: 1px solid teal;
      }
      .dropdown-tab {
        width: 50%;
      }
      p {
        color: $white;
      }
      .notification-info-header {
        font-size: 1.17em;
        text-decoration: underline;
      }
    }

    @include mobile-small {
      &:after {
        content: unset;
      }
      border-bottom: 2px solid teal;
      min-width: 10% !important;
      left: 0 !important;
      right: 0 !important;
      width: 100vw;
      position: fixed;
      top: 46px !important;
    }
  }

  .select-group {
    &.alert-group-list {
      max-height: 60vh;
    }

    @include mobile-only {
      box-shadow: unset;
    }
  }

  .select-group-item {
    height: auto;
    position: relative;

    &.read {
      opacity: 0.6;
    }

    &:not(.header) > div {
      //padding: 0 7.5px;
    }

    &:after {
      content: "";
      border: 2px solid $light-teal;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: none;
    }

    &:hover,
    &:focus,
    &.hover,
    &.focus {
      &:after {
        display: block;
      }
    }

    /*Commenting this out b/c impossible to debug*/
    @include mobile-only {
      // pointer-events: none;
    }

    @include mobile-only {
      //flex-direction: column;
      background-color: $blk-blue;
      &.header > div {
        color: $baby-blue;

        &:after {
          content: unset;
        }
      }
      .resolved-column {
        text-align: center;
      }
      input[type="checkbox"] {
      }
      .alert-log-heading {
        font-size: 1.4em;
        color: $white;
        //text-align: left;
      }

      .alert-log-time,
      .alert-log-subheading {
        text-align: left;
        color: $white;
        font-size: 0.9em;
        white-space: pre-wrap;
      }

      .notification-message {
        color: $white;
      }
      :last-child {
        white-space: pre-wrap;
      }
    }
  }

  .notification-info {
    margin-bottom: 0;
    font-weight: $regular-font-weight;
    font-size: 0.9em;

    @include no-wrap;

    &:first-child {
      margin-top: 0.6em;
    }
  }

  .resolve-loader {
    font-size: 2.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .notification-control-wrapper {
    @include mobile-only {
      display: inline !important;
      button:first-of-type {
        float: left !important;
      }
      button:last-of-type {
        float: right !important;
      }
      button {
        color: $teal;
      }
    }
  }

  .alert-control-wrapper > .alert-control-small-last-container {
    display: flex;

    @include mobile-small {
      margin-top: -10px;
    }

    > .close-button {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .alert-control-wrapper {
    @include mobile-small {
      #resolve-all-alerts-button {
        margin-bottom: 12px;
        order: 1;
      }
      #resolve-checked-close-buttons {
        order: 2;

        #resolve-checked-alerts {
          color: #9F9F9F;

          &.u-text-teal {
            color: $teal;
          }
        }
      }
    }
  }

  .alert-control-wrapper,
  .notification-control-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    @include mobile-small {
      flex-direction: column;
    }

    @include mobile-small {
      button:not(#resolve-checked-alerts) {
        color: $teal;
      }
      font-size: 16px;
    }
    @include mobile-only {
      button:not(#resolve-checked-alerts) {
        color: $teal;
      }
      button#resolve-checked-alerts {
        color: #9F9F9F;

        &.u-text-teal {
          color: $teal;
        }
      }
      font-size: 16px;
    }

    .close-button {
      display: none;
      @include mobile-only {
        display: block;
        &:before {
          content: "+";
          display: inline-block;
          font-size: 20px;
          transform: rotate(45deg) translate(0, 12%);
        }
      }
    }

    .btn-link {
      @include mobile-small {
        margin-right: auto;
        float: unset !important;
      }
    }

    .background-gradient {
      @include mobile-small {
        background: linear-gradient(rgba(0, 19, 33, 0), rgb(0, 19, 33));
        height: 83px;
        margin-top: -83px;
        position: relative;
        z-index: 99999;
        transition: visibility 0.5s, opacity 0.5s;
      }

      &.hide-gradient {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.5s, opacity 0.5s;
      }
    }
  }
}

.dropdown-menu .MuiTabPanel-root {
  padding: 0;
}

.dropdown-menu .MuiBadge-root {
  color: $teal;
  font-size: $p-size;

  .MuiBadge-badge {
    right: -8px;
  }
}
