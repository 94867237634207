.info {
  display: flex;
  font-size: $small-size;
  justify-content: space-between;
  margin-bottom: 1em;

  p {
    margin-bottom: 5px;
  }
}

.info-alert {
  max-width: 65%;
}

.alert-value {
  font-size: 1.5em;
}

.info-device {
  text-align: right;
  padding-top: 10px;
}
