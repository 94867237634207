@import '../../Styles/variables';
@import '../../Styles/mixins';

.location-dropdown {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-top: -6px;
  display: block;

  .dropdown-toggle {
    width: 100%;
    height: 4.57143em;
    padding: 10px 15px;
    display: block;

    &:not(.disabled) {
      @include link-active {
        cursor: pointer;
        background-color: $dropdown-link-hover-bg;
      }
    }
  }

  .dropdown-body {
    width: 100%;
    background-color: $body-blue;
    padding-left: 7px;

    &.dropdown-expanded {
      height: 44vh;
      overflow-y: auto;
    }

    &-item {
      padding: 7px 12px;
      display: block;
      border-bottom: 1px solid $dark-blue;
      border-left: 1px solid $dark-blue;

      @include link-active {
        cursor: pointer;
        background-color: $dropdown-link-hover-bg;
      }
    }

    .no-results {
      padding: 20px 12px;
      text-align: center;
    }
  }

  .custom-scrollbar {
    &::-webkit-scrollbar {
      background-color: $marine-blue;
      position: absolute;
      width: 10px;
      margin-right: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(128, 194, 255, 0.4);
      border-radius: 6px;
      width: 8px !important;

      @include link-active {
        background-color: rgba(128, 194, 255, 0.5);
      }
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }
  }

  .find-location {
    padding: 5px;
  }


  .location-list-header {
    display: flex;
    align-items: center;
    padding: em(3) em(3);
    border-bottom: 1px solid $teal;

    .location-list-title {
      color: $teal;
      text-transform: uppercase;
      font-size: $small-size;
      margin: 0;
      font-weight: 500;
    }

    .btn-location {
      color: $teal;
      margin-right: em(9);
    }
  }
}
