@import "../../Styles/variables";
@import '../../Styles/mixins';

.policy-window {
  background-color: $marine-blue;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-height: calc(100vh - 64px);

  .button-row {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
  }

  .main-panel {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow: hidden;
  }

  .country-select {
    width: 300px;
    margin: 0;
  }

  .text-editor {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: hidden;

    .ql-container {
      display: flex;
      overflow: scroll;
      flex: 1 1;

      .ql-editor {
        flex: 1 1;
      }
    }

  }
}