.range-picker-wrapper {
  display: flex;
  gap: 8px;

  .from,
  .to {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: start;

    .input-label {
      margin: 0;
    }
  }

  &.vertical {
    .from,
    .to {
      align-items: start;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}