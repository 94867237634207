@import "../Styles/variables";

.account-settings-section {
  margin-top: 15px;

  & > * {
    margin-bottom: 15px;
  }

  hr {
    border-top: 1px solid rgba($color: #000000, $alpha: 0.25);
  }
}

.info-subtitle {
  font-size: $ultra-small-size;
  margin-bottom: 2rem;
  a {
    color: $baby-blue;
  }
}

.manage-user-row-to-left {
  margin-left: -15px;
  padding-left: 0;
}
