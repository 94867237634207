@import "../../Styles/variables";
@import "../../Styles/mixins";

.bapi-main.mod-dashboard .export-header.header-row {
  display: flex;
  flex-wrap: wrap;
  height: unset;
  justify-content: space-between;
  margin-bottom: 0;
  min-height: em($header-row-height);
  padding: 0 20px;
  position: unset;
  width: 100%;

  > .form-group,
  > * {
    margin: 4px 0;
  }
}

.status-wrapper {
  display: flex;
  align-items: center;

  .status-icon {
    font-size: $h4-size;
    margin-right: 1rem;

    &.color-alert {
      color: $alert;
    }

    &.color-success {
      color: #11e71a;
    }
  }
}

.export-nav {
  .left-rail-nav-item {
    align-items: center;
    display: flex;
    gap: 2rem;
  }
}

.graph-holder {
  background-color: $body_blue;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  #line-chart {
    position: relative;
    width: 100%;
    height: 350px;
    display: flex;
    flex: 1;
  }
}

.export-menu-mobile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.mobile-export-item {
  align-items: center;
  display: flex;
  gap: 1.5em;
  justify-content: space-between;

  &-content {
    flex: 1;
  }

  .export-item-row {
    display: flex;
    margin-bottom: 1em;

    > * {
      width: 50%;
    }
  }
}

.export-table .MuiTableCell-body {
  padding: 16px;
}

.export-no-results {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

.export-main.main-panel {
  overflow: auto;
  padding-top: 30px;
  position: relative;
}

.preset-name-wrapper {
  display: flex;

  > *:first-of-type {
    flex: 1;
  }

  > *:last-of-type {
    display: flex;

    .btn {
      margin: auto 0 auto 12px;
    }
  }

  @include mobile-only {
    flex-direction: column;

    > *:last-of-type {
      justify-content: flex-end;
    }
  }
}