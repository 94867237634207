@import "../../Styles/mixins";
@import "../../Styles/variables";

.switch-account-type-button {
  margin-top: 12px;

  @include mobile-only {
    justify-content: center;

    > button.MuiButtonBase-root {
      margin: auto;
      width: 80%;
    }
  }
}

.autocomplete-account {
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 10px) !important;
  }

  .MuiAutocomplete-listbox {
    padding: 0 !important;
  }
}

.autocomplete-account .input-holder .input-line {
  margin: 0;
  padding-right: 0;
}

.account-search-input.form-group {
  margin: 0;
}

.account-header {
  .header-row-section {
    @include mobile-only {
      margin-bottom: 5px;
    }
  }

  .header-row-title {
    @include mobile-only {
      margin: 0;
    }
  }
}

.mobile-name {
  @include mobile-only {
    margin-bottom: 20px;
  }
}

.account-chip {
  @include mobile-only {
    margin-bottom: 5px;
  }
}

@include mobile-only {
  .account-header {
    height: unset;
  }
}

.account-members {
  padding-top: 20px;
  > div {
    @include mobile-only {
      overflow-x: auto;
    }
  }
  ul {
    @include mobile-only {
      min-width: 800px;
    }
  }
}

.device-count {
  font-size: $small-size;
  font-weight: $semi-bold-font-weight;
  color: $light-gray;
  @include mobile-only {
    font-size: $h5-size;
  }
  > span {
    padding-left: 3px;
    color: $white;
  }
  &:last-child {
    padding-left: 10px;
    @include mobile-only {
      padding-left: 20px;
    }
  }
}

.total-devices {
  font-size: $h5-size;
  font-weight: $regular-font-weight;
  @include mobile-only {
    margin: 15px 0;
  }
  > span {
    padding-left: 3px;
    color: $teal;
    font-weight: $bold-font-weight;
  }
}

.mobile-subheader-devices {
  align-items: baseline;
}

.demo-change-modal-wrapper {
  min-height: 355px;
  .demo-date-picker-wrapper {
    margin-bottom: 2rem;
    display: grid;
    max-width: 200px;

    &:nth-of-type(2) {
      margin-bottom: 4.5rem;
    }
  }

  .demo-info-bordered-text {
    padding: 1rem;
    margin-top: 3.5rem;
  }
}
