@import '../../Styles/variables';
@import '../../Styles/mixins';

.mod-dashboard {
  display: flex;
  flex: 1;
  flex-direction: column;

  .fa-spinner {
    font-size: 7rem;
    margin: auto;
  }
}

.header-icon {
  @include tablet-and-mobile {
    color: $teal;
  }

  @include mobile-small {
    display: none;
  }
}

.switch-gateway-mode {
  display: flex;
  align-items: center;
}

.dashboard {
  display: flex;
}

.header-select {
  margin-bottom: 0 !important;
  margin-left: 15px;
}

.dashboard-content {
  padding: 0 $grid-gutter-default $grid-gutter-default $grid-gutter-default;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > button {
    height: fit-content;
  }
}

.setup-container {
  align-items: stretch;
  justify-content: space-around;
  text-align: center;
  width: 100%;

  &.link-device-container .setup-instruction {
    @include mobile-only {
      flex-direction: column;
    }
  }
}

.setup-title {
  width: 100%;
  font-weight: $regular-font-weight;
  font-size: 2em;

  @include mobile-only {
    font-size: 1.5em;
  }
}

.setup-instruction {
  align-items: center;
  display: flex;
  gap: 16px;
  background-color: rgba($white, 0.1);
  margin: 2em 0;
  padding: 30px 15px;
  text-align: left;
  width: 100%;

  > i {
    color: $baby-blue;
    font-size: $h1-size;
  }

  > p {
    margin: 0;
  }

  &.left {
    width: 50%;
  }

  &.right {
    width: 50%;
    background-color: rgba($white, 0.2);
  }

  @include mobile-only {

    &.left,
    &.right {
      width: 100%;
      margin: 0;
    }

    &.left {
      margin-top: 2em;
    }

    &.right {
      margin-bottom: 2em;
    }
  }

}

.dashboard-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 65px;
  background-color: rgba($blk-blue, 0.9);
  color: $white;
  z-index: 100;

  p {
    position: absolute;
    top: 40vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#left-rail-actions {
  display: flex;

  > #left-rail-action:not(:last-child) {
    margin-right: .5rem;
  }
}

.device-firmware-alert-badge {
  left: 66% !important;
  top: 30% !important;
}

.load-more-button {
  margin-top: 12px;

  @include mobile-only {
    align-items: center;
    display: flex;
    justify-content: center;

    .btn {
      width: 50%;
    }
  }
}

.mod-dashboard .header-row-info .input-label {
  white-space: nowrap;
}

.mobile-dashboard-filters {
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;

  }
  .modal-header {
    background-color: $card-blue;
    border: 0;
  }
}

.filter-label {
  color: $teal;
  margin: auto auto auto -5px;
}

.filter-chip {
  .MuiChip-label{
    color: $white;
    font-size: $h4-size;
  }
  .MuiChip-deleteIcon {
    color: $white !important;
  }
}

.page-dashboard-auto {
  display: flex;
  flex-direction: column;

  &.bapi-main.mod-dashboard {
    @include desktop-only {
      > .header-row {
        width: 100%;
        position: sticky;
        top: 64px;
      }
    }

  }

  @include desktop-only {
    > .main-panel.extended {
      padding-top: 0;
    }
  }

  .show-more-filters-button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 20px;
    cursor: pointer;
  }
}
.chip-container {
  @include mobile-only {
    flex-wrap: wrap;
    height: unset;
    align-content: baseline;
  }
  div {
    border-radius: unset;
    background-color: $dark-blue;
    border: $white solid 1px;
    max-width: 200px;
  }
}
.close-icon {
  width: 25px;
  height: 10px;
  cursor: pointer;
}

.extended {
  margin-bottom: 30px;
}

.filter-container {
  position: fixed;
  left: em(-$left-rail-width);
  top: em($header-row-height);
  width: em($left-rail-width);
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  background-color: $card-blue;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  transition: left 0.3s ease;
  padding: 0 0 100px;
  &.extended {
    left: 0;
  }
  @include mobile-only {
    flex-wrap: unset;
    flex-direction: column;
    width: 100%;
    position: initial;
    padding-left: 0 !important;
  }

  @include tablets-only {
    z-index: 1100;
  }
  >div {
    margin-right: unset;
    width: 100%;
  }
}
.filter-container::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.more-chips {
  margin: 0 20px 0 auto !important;
  p {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 0 10px;
  }
}

.additional-chips {
  padding: 10px !important;
  min-width: em(300) !important;
  flex-wrap: wrap;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  align-items: baseline;
  font-size: $h5-size;
  p {
    margin: 0;
  }
}
.filter-container.extended {
  .MuiCheckbox-root {
    svg {
      fill: white;
    }
  }
}

.filter-accordion {
  width: 100%;
  font-size: $p-size;
  margin-right: unset;

  .filter-accordion-header {
    .MuiAccordionSummary-content {
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      &.Mui-expanded {
        margin: unset !important;
        margin-top: 0 !important;
      }

      p {
        width: 60%;
      }
    }
  }
}

.dropdown-filters {
  padding: 10px;
}

.expand-icon {
  color: $teal;
}

.filter-option {
  color: white !important;
  text-transform: capitalize !important;
  font-size: $p-size !important;
  padding: 0 0 0 10px !important;
    .Mui-checked {
      color: white !important;
    }
}