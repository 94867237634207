@use "quill/dist/quill.snow";
@import "./src/Styles/variables";
@import "./src/Styles/mixins";

.bapi-main {
  background-color: $body-blue;
  width: 100%;

  &.mod-dashboard {
    @include desktop-only {
      padding: 0 0 0 em($left-rail-width);

      .header-row {
        width: calc(100% - em($left-rail-width));
      }
    }
  }
}

.left-rail-toggler {
  width: 1.1em;
  height: 1.5em;
  position: relative;
  display: none;

  &:active,
  &:focus,
  &:hover {
    background-color: inherit;
    border: none;
  }

  & > .icon {
    width: 0.95em;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: block;
    left: 0;
    background-color: $white;
    transition: 0.3s opacity $ease-out;
  }

  &:after,
  &:before {
    width: 100%;
    height: 2px;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    background-color: $white;
    transition: 0.3s transform $ease-out;
  }

  &:after {
    bottom: 0.3em;
  }

  &:before {
    top: 0.3em;
  }

  &.open {
    & > .icon {
      opacity: 0;
      transition: 0.3s opacity $ease-out;
    }

    &:after {
      transform: rotate(-45deg);
      transform-origin: left center;
      transition: 0.3s transform $ease-out;
    }

    &:before {
      transform: rotate(45deg);
      transform-origin: left center;
      transition: 0.3s transform $ease-out;
    }
  }

  @include tablet-and-mobile {
    display: block;
  }
}

.site-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  opacity: 0.7;
}

// Override an undesirable effect on disabled dropdowns
.select.form-control[disabled] {
  background: #222222;
}

.form-group.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    flex: 1;
  }

  input,
  select {
    background: #043053;
    flex: 0 0 30%;
  }
}

.ql-content-display {
  @extend .ql-editor;

  p:after {
    content: "";
    display: inline-block;
    width: 0;
  }

  li {
    list-style-position: inside;
  }

  ul > li {
    list-style-type: disc;
  }

  ol > li {
    list-style-type: decimal;
  }
}

.ReactCollapse--collapse {
  transition: 0.2s ease-in-out;
}

@include tablet-and-mobile {
  .bapi-header:not(.unauthenticated) > * {
    width: 33%;

    & .bapi-logo-holder {
      @include tablet-and-mobile {
        text-align: center;
      }
    }
  }
}

.bapi-header.unauthenticated .bapi-logo-holder {
  text-align: center;
}
