@import "../Styles/variables";
@import "../Styles/mixins";

.right-panel {
  position: relative;
}

.delete-button {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1000;
  &.currencies {
    position: absolute !important;
    right: 15px !important;
    top: 10px !important;
  }
}

.add-button {
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

div.modal-body.locations-modal-body .left-rail-nav-item {
  padding: 10px 0 10px 1.5em;
}

div.modal-body.locations-modal-body .left-rail-nav-item > a {
  color: $white;
  cursor: pointer;
  display: block !important;
}

div.modal-body.locations-modal-body button.delete-button {
  position: absolute;
}

.manage-locations-title-section {
  display: flex;
  padding: 10px 15px 0 15px;
  justify-content: space-between;
  width: 100%;
  vertical-align: center;
  color: $teal;

  .left-rail-nav-header-title {
    align-self: center;
  }

  .btn-location {
    margin-right: 5px;
  }

  .add-button i {
    position: relative;
    top: 1px;
  }
}

input.input-with-icon {
  font-family: $primary-font-family, FontAwesome;
}

div.modal-body.locations-modal-body {
  div.left-rail {
    position: fixed;
    width: em($left-rail-width);
    height: unset;
  }
}

@include mobile-only {
  .mobileHidden {
    display: none;
  }
  .modal-body.locations-modal-body {
    padding: 15px;

    .locations-select-mobile {
      background-color: black;
      padding: 6px 12px;

      div.MuiSelect-select {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      svg.MuiSvgIcon-root {
        color: #04cca0;
        transform: scale(1.5, 1.5);
      }
    }

    #or-text {
      text-align: center;
      font-size: 16px;
      margin-bottom: -10px;
    }

    #locations-modal-right-panel {
      margin-left: 0;

      #city-state-zipcode-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          flex-basis: 50%;
        }

        > #country-select-mobile {
          width: 0;
        }
      }

      #country-phone-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          flex-basis: 100%;
        }
      }

      #allocated-users-container {
        > input:first-of-type {
          margin-bottom: 10px;
        }

        > ul {
          overflow: hidden;
          max-height: unset;
        }

        div.pagination-wrapper {
          justify-content: center;

          > button {
            min-width: unset;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            //align-self: center;
          }

          > button:first-of-type > span:first-of-type {
            display: none;
          }

          > button:last-of-type > span:first-of-type {
            display: none;
          }

          > nav {
            align-content: center;
          }
        }
      }
    }
  }

  .modal-footer .locations-modal-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    > button.btn {
      flex-basis: 45%;
      margin-left: 0;
    }

    .delete-button-mobile {
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      > img {
        width: 13px;
        height: 14px;
        margin-right: 7px;
      }
    }
  }
}
