@import "../Styles/mixins";

.line-chart-holder {
  padding: 8px 8px 0 0;
  position: relative;
  width: 100% !important;

  .chart-load-indicator {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 100%;
  }
}

#root .bb {
  display: flex;
}

path.bb-shape {
  shape-rendering: optimizeSpeed;
}

@include tablet-and-mobile {
  .show-tooltip {
    .bb-tooltip-container {
      display: unset !important;
      visibility: unset !important;
    }
  }
}
