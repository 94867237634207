@import "../../Styles/variables";
@import "../../Styles/mixins";

.device-card-wrapper {
  width: calc(25% - 15px);

  @include desktop-only {
    min-width: 210px;
    max-width: 250px;

    &.large {
      width: calc(50% - $grid-gutter-default);
      min-width: 435px;
      max-width: 515px;
    }
  }

  @include tablets-only {
    width: calc(50% - $grid-gutter-default / 2);

    .device-card {
      height: em(160);
    }

    .device-card-body {
      font-size: 0.7em;
    }

    .device-card-body-aside-item {
      padding: 4px $grid-gutter-default / 2;
    }
  }

  @include mobile-only {
    width: 100%;
  }
}

.device-card {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $card-blue;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  height: em(188);
  padding: 0;
  border-radius: 0;
  border: 0;
  width: 100%;

  @include link-active {
    box-shadow: 0 7px 6px 1px rgba(0, 0, 0, 0.18), 0 1px 0 0 rgba(0, 0, 0, 0.3), 0 1px 2px 1px rgba(0, 0, 0, 0.3),
      0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-image: radial-gradient(circle at 50% 0, #1565b0, #02386b);
  }

  &-header {
    padding: 8px $grid-gutter-default;
    min-height: em(55);
    background-image: linear-gradient(to right, #0061a6 1%, #1377bf 49%, #0061a6);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(88, 172, 255, 0.5);
    user-select: none;
    cursor: pointer;

    @include tablet-and-mobile {
      min-height: em(75);
    }
  }

  &-title,
  &-title-row {
    display: flex;
    font-size: $p-size;
    font-weight: $regular-font-weight;
    line-height: 1.7;
    margin: 0;
  }

  &-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    width: 100%;
  }

  &-info {
    display: flex;
    justify-content: space-between;

    p {
      max-width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-text {
      font-size: $small-size;
      opacity: 0.7;
      margin: 0;
    }

    &-icons {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-top: -1px;
      white-space: nowrap;

      @include tablet-and-mobile {
        margin: 0 0 0 auto;
      }

      .dropdown-menu:after {
        right: 0.15em;
      }
    }
  }

  &-body-main {
    width: 100%;
    cursor: pointer;
    position: relative;
    height: 100%;

    .device-card-value-side-sub {
      font-size: 0.6em;
    }

    @include tablet-and-mobile {
      align-items: center;
      display: flex;
      padding: 4px 16px;
      p {
        margin: 0;
      }

      > * {
        width: 33%;
      }
    }
  }

  &-value {
    font-size: $h1-size;
    font-weight: $bold-font-weight;
    color: $light-teal;
    text-align: center;
    margin: 11px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: baseline;

    @include mobile-only {
      font-size: $h2-size;
    }

    @include small-mobile-only {
      font-size: $h4-size;
    }
  }

  &-value-label {
    color: rgba($white, 0.4);
    font-size: $p-size;
    text-align: center;
    text-transform: uppercase;
    margin: 14px 0 10px 0;

    @include tablet-and-mobile {
      color: $baby-blue;
    }

    @include mobile-only {
      font-size: $small-size;
    }

    @include small-mobile-only {
      font-size: $ultra-small-size;
    }
  }

  &-footer {
    text-transform: uppercase;
    font-size: $small-size * 0.9;
    font-weight: $bold-font-weight;
    text-align: center;
    padding: 6px $grid-gutter-default 7px $grid-gutter-default;
    position: absolute;
    left: -1px;
    bottom: -1px;
    right: -1px;
    line-height: 1;
  }
}

// Device card states
.device-card {
  &:after {
    content: "";
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    display: none;
    pointer-events: none;
  }

  &.selected {
    &:after {
      display: block;
      border: 3px solid $light-teal;
    }
  }

  &.muted,
  &.offline {
    .device-card-header {
      background-color: #2c4960;
      background-image: none;
      box-shadow: none;
    }

    @include link-active {
      background-image: none;
      background-color: rgba($card-blue, 0.35);
    }
  }

  &.alert {
    .device-card-header {
      background-image: radial-gradient(circle at 49% 0, #e51b00, #c30000);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 1px 0 #a20101, inset 0 1px 0 0 rgba(255, 189, 189, 0.5);
    }
  }
}

.alert {
  border: none;
  border-radius: 0;
  margin: 0;
}

.device-card-body-main {
  &.alert {
    background-image: radial-gradient(circle at 50% 38%, #fdc135, #fd6c0c);
    border-radius: 0;
    color: $alert;

    & > .device-card-value,
    & > .device-card-value-label {
      color: $alert;
    }

    .device-card-footer {
      background-color: $alert;
      color: $white;
      font-size: 0.7em;
    }
  }

  &.warning {
    & > .device-card-value {
      color: $warning;
    }

    .device-card-footer {
      color: $blk-blue;
      background-color: $warning;
    }
  }
}

.device-card-body-aside-item {
  align-items: center;

  &.alert {
    border-left: 8px solid $alert;

    & > .device-card-value,
    & > .device-card-value-label {
      color: $alert;
    }
  }

  &.warning {
    border-left: 8px solid $warning;

    & > .device-card-value {
      color: $warning;
    }
  }
}

.device-card-badge {
  position: absolute;
  right: -8px;
  top: -10px;
  z-index: 100;
}

.device-card-icon {
  font-size: 0.7em;

  &.icon-barometric_pressure {
    font-size: 16px;

    @include desktop-only {
      font-size: 0.4em;
    }
  }
}

.device-card-value {
  &-main,
  &-side-sub {
    color: $white;
  }
}

.device-card-value-side {
  margin-right: 8px;

  &-sub {
    align-self: flex-start;
    text-align: left;

    @include tablet-and-mobile {
      align-self: baseline;
    }
  }
}

.device-card-body {
  flex: 1;

  @include desktop-only {
    &.reverse-layout {
      flex-direction: column-reverse;
      justify-content: unset;

      p {
        margin: 0;
      }

      .device-card-body-main {
        align-items: center;
        display: flex;
        padding: 4px 16px;

        > * {
          width: 33%;
        }
      }

      .device-card-body-aside {
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

        :first-child {
          border-left: none;
        }
      }
    }

    &:not(.reverse-layout) .device-card-body-aside-item:last-of-type {
      border-bottom: unset;
    }
  }
}

.device-card-large {
  width: 100%;

  .device-card-body {
    display: flex;
    justify-content: space-between;

    @include tablet-and-mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.device-card-body-aside {
  width: em(244);
  height: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));

  @include tablet-and-mobile {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

    :first-child {
      border-left: none;
    }
  }

  &-item {
    padding: 8px $grid-gutter-default 8px 0;
    display: flex;
    cursor: pointer;
    border-left: 1px solid $royal-blue;
    border-bottom: 1px solid $royal-blue;

    @include desktop-only {
      :last-child {
        border-bottom: unset;
      }
    }

    @include tablet-and-mobile {
      padding: 8px 4px;
    }

    @include small-mobile-only {
      font-size: $ultra-small-size;
      padding: 0;
    }

    .icon-barometric_pressure {
      font-size: 0.8em;
    }
  }

  .device-card-value {
    align-items: center;
    display: flex;
    font-size: $p-size;
    justify-content: center;
    margin: 0;
    text-align: left;
    white-space: nowrap;

    > * {
      align-self: baseline;
    }

    @include tablet-and-mobile {
      font-size: $h5-size;
    }

    .open-closed-value {
      @include tablet-and-mobile {
        font-size: 0.9rem;
      }
    }

    > :first-child {
      font-size: $h4-size;
      width: 30%;
      text-align: center;
    }

    > :last-child {
      margin-left: 4px;
    }

    .icon-barometric_pressure {
      background-position: em(-1) em(-142);
      background-size: em(110);
      height: 1.4em;
      margin: 0 6%;
      width: 1.4em;
    }
  }
}

button.show-details-button {
  font-size: $h5-size;

  @include small-mobile-only {
    font-size: $small-size;
  }
}

button.device-menu {
  padding: 0;
}
