@import "../../../Styles/variables";
@import "../../../Styles/mixins";

#step1 {
  ul {
    margin: 10px 0 15px 0;
    padding-left: 0;
  }
}

#step2 {
  div.dropdown {
    margin: 10px 0 15px 0;
    width: 50%;
    background-color: $blk-blue;
    border-bottom: unset;

    &-open {
      border: 1px solid $teal
    }
  }

  ul {
    li.select-group-item {
      border-bottom: unset;
      background-color: $blk-blue;
    }
  }

  div.device-item:hover {
    background-color: $marine-blue;
  }

  div.device-item {
    > * {
      margin: 0;
      width: 50%;
    }

    @include tablet-and-mobile {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > * {
        width: 100%;
      }

      &:not(:first-of-type) {
        margin-top: 1px solid $royal-blue;
      }
    }
  }
}

#step3 {
  .graph-holder,
  .line-chart-holder {
    min-height: 500px;
  }

  .bb text {
    fill: $teal;
    font-size: 20px;
  }
}

.report-footer-buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include mobile-only {
    align-items: unset;
  }

  &-main {
    display: flex;

    @include mobile-only {
      margin-bottom: 8px;

      > * {
        flex: 1;
      }
    }
  }
}

.generate-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000085;
}

.export-modal {
  table {
    white-space: nowrap;
  }
}

.export-modal-table {
  .export-header-select {
    display: flex;
    gap: 1em;
  }

  .MuiTableCell-body {
    padding: 16px;
  }
}

.set-point-action-row {
  display: flex;

  &:not(.set-point-action-buttons) {
    justify-content: space-between;
  }

  &.set-point-action-buttons {
    margin-top: 1em;
  }

  > .pull-right {
    text-align: end;
  }

  @include mobile-only {
    justify-content: space-between;

    &.set-point-action-buttons {
      flex-direction: row-reverse;
    }

    > * {
      flex: 1;

      &.btn-plain {
        text-align: left;
      }
    }
  }

}